<!--全景数据转换-->
<template>
  <div>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        sending: false,
        updateing: false,
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {}
    },
    validations() {
      var v = {
        form: {
          title: {
            required: this.$required,
            minLength: this.$minLength(2),
            maxLength: this.$maxLength(50)
          },
          links: {
            maxLength: this.$maxLength(4),
            $each: {
              title: {
                required: this.$required,
                minLength: this.$minLength(2),
                maxLength: this.$maxLength(4)
              },
              link: {
                //required: this.$required,
                minLength: this.$minLength(2),
                maxLength: this.$maxLength(250),
                isLink: (value, p) => {
                  if (p.type == 'Url') {
                    if (value) {
                      return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(value)
                    } else {
                      return false
                    }
                  }
                  if (p.type == 'Email') {
                    if (value) {
                      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
                    } else {
                      return false
                    }
                  }
                  if (p.type == 'Tel') {
                    if (value) {
                      return /^((0\d{2,3}-\d{7,8})|(1[35847]\d{9}))$/.test(value)
                    } else {
                      return false
                    }
                  }
                  return true
                }
              },
              image: {
                isImage: (value, p) => {
                  if (p.type == 'Image') {
                    if (value && value.guid) {
                      return true
                    } else {
                      return false
                    }
                  }
                  return true
                }

              },
              gps: {
                isGps: (value, p) => {
                  if (p.type == 'Location') {
                    return p.gpsLat != 0 || p.gpsLng != 0
                  }
                  return true
                }
              }
            }
          },
          hotspots: {
            maxLength: this.$maxLength(20),
            $each: {
              url: {
                isUrl: (value, p) => {
                  if (p.linkType == 'Link') {
                    if (value) {
                      return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}(\.[a-zA-Z0-9()]{1,6})\b(:[0-9]{1,5})?([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(value)
                    } else {
                      return false
                    }
                  }
                  return true
                }
              }
            }
          }
        }
      }
      console.log('rv', this.config.validations)
      var v2 = this.validationsTransform(this.config.validations || {})
      console.log('tv', v2)
      var rv = this.objAdd(v2, v)
      console.log('rv', rv)
      return rv
    },
    computed: {
      urls() {
        return this.publicData.urls
      },
      params() {
        return this.publicData.params
      },
      editHotspotGuid() {
        return this.publicData.editHotspotGuid || {}
      },
      hotspots() {
        if (this.publicData.form) {
          return this.publicData.form.hotspots || []
        }
        return []
      },
      form() {
        return this.publicData.form || {}
      },
    },
    watch: {
      //  form(val, oldval) {
      //    console.log('form Changed', val, oldval)
      //  }
      hotspots(val, oldval) {
        this.getFunc('changePublicData')({
          blockCount: this.blockCount({ hotspots: val }),
          hotspotCount: this.hotspotCount({ hotspots: val }),
        })
      },
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
          formChage: this.formChage,
          changePostprocessing: this.changePostprocessing,
          updatePano: this.updatePano,
          hotspotMove: this.hotspotMove,
          setPanoView: this.setPanoView,
          setLinkView: this.setLinkView,
          save: this.save
        },
        events: {
          titleInvalid: {
            target: 'baseSettingModel',
            name: 'show'
          },
          hotspotsInvalid: {
            name: 'renewSence',
            arg: 'hotspot'
          }
        }
      })
      this.updatePano()
    },
    created() {
    },
    destroyed() {
    },
    methods: {
      objAdd(obj1, obj2) {
        if (typeof obj1 == "object" && typeof obj2 == "object") {
          var r = {}
          for (var i in obj1) {
            r[i] = this.objAdd(obj1[i], obj2[i])
          }
          for (var i in obj2) {
            if (!r[i]) {
              r[i] = obj2[i]
            }
          }
        } else {
          return obj1
        }
        return r
      },
      validationsTransform(v) {
        var r = {}
        for (var i in v) {
          if (typeof v[i] == 'string') {
            if (v[i] == 'required') {
              r[i] = this.$required
            } else if (v[i].indexOf('maxLength(') == 0) {
              var int = Number(v[i].slice('maxLength('.length, v[i].length - 1))
              r[i] = this.$maxLength(int)
            } else if (v[i].indexOf('minLength(') == 0) {
              var int = Number(v[i].slice('minLength('.length, v[i].length - 1))
              r[i] = this.$minLength(int)
            } else if (v[i].indexOf('isMobile') == 0) {
              r[i] = this.$isMobile
            } else if (v[i].indexOf('isTel') == 0) {
              r[i] = this.$isTel
            } else if (v[i].indexOf('isUrl') == 0) {
              r[i] = this.$isUrl
            } else if (v[i].indexOf('notZero') == 0) {
              r[i] = this.$notZero
            }
          } else {
            r[i] = this.validationsTransform(v[i])
          }
        }
        return r
      },

      formChage(type) {
        console.log('formChage', type)
        this.updatePano()
        switch (type) {
          case 'weather':
            this.getFunc({ target: 'FullPano', name: 'setWeather' })()
            break
          case 'postprocessing':
            this.getFunc({ target: 'FullPano', name: 'setPostprocessing' })()
            break
          case 'hotspot':
            this.getFunc({ target: 'FullPano', name: 'setHotspots' })()
            break
          default:
        }
      },
      hotspotMove({ item, ath, atv }) {
        item.ath = ath
        item.atv = atv
      },
      changePostprocessing(postprocessing) {
        console.log('changePostprocessing', postprocessing)
        this.form.postprocessing = postprocessing
        this.getFunc({ target: 'FullPano', name: 'setPostprocessing' })()
      },
      setLinkView(linkViewData) {
        var item = null
        for (var i in this.form.hotspots) {
          if (this.form.hotspots[i].guid == this.editHotspotGuid) {
            item = this.form.hotspots[i]
          }
        }
        for (var i in linkViewData) {
          item[i] = linkViewData[i]
        }
      },
      setPanoView(view) {
        for (var i in view) {
          this.form[i] = view[i]
        }
      },
      updatePano(pano) {
        var p = pano || this.form
        this.getFunc('changePublicData')({ pano: p, blockCount: this.blockCount(p), hotspotCount: this.hotspotCount(p), $v: this.$v })
      },
      hotspotCount(pano) {
        var p = pano || this.form
        return this.getHotspots(p).length
      },
      getHotspots(pano) {
        var p = pano || this.form
        if (!p.hotspots) {
          return []
        }
        return p.hotspots.filter((v) => v.type != 'Block')
      },
      blockCount(pano) {
        var p = pano || this.form
        return this.getBlocks(p).length
      },
      getBlocks(pano) {
        var p = pano || this.form
        if (!p.hotspots) {
          return []
        }
        return p.hotspots.filter((v) => v.type == 'Block')
      },
      linkView(pano) {
        this.getFunc('changePublicData')({ pano: pano })
        this.getFunc('renewSence', 'linkView')
      },
      save() {
        if (this.updateing) {
          return
        }
        this.$v.$touch()
        if (this.$v.$invalid) {
          for (var j in this.$v.form.links.$each.$iter) {
            if (!this.$v.form.links.$each.$iter[j].url.isUrl) {
              this.$message({
                message: `链接“${this.$v.form.links.$each.$iter[j].$model.title || `${Number(j) + 1}`}”的 Url 不是有效的格式`,
                type: 'warning',
                zIndex: 9999,
                showClose: true,
                onClose: () => {
                  this.$emit('linksInvalid')
                }
              })
            }
          }
          for (var j in this.$v.form.hotspots.$each.$iter) {
            if (!this.$v.form.hotspots.$each.$iter[j].url.isUrl) {
              this.$message({
                message: `热点“${this.$v.form.hotspots.$each.$iter[j].$model.title || `${Number(j) + 1}`}”的 Url 不是有效的格式`,
                type: 'warning',
                zIndex: 9999,
                showClose: true,
                onClose: () => {
                  this.$emit('hotspotsInvalid')
                }
              })
            }
          }
          if (this.$v.form.title.$invalid) {
            this.$message({
              message: `请检查标题`,
              type: 'warning',
              zIndex: 9999,
              showClose: true,
              onClose: () => {
                this.$emit('titleInvalid')
              }
            })
          }
          return
        }
        this.updateing = true;
        this.getFunc('changePublicData')({ updateing: this.updateing })
        this.$axios.put(this.urls.panoSave({ panoId: this.form.guid }), this.form)
          .then((response) => {
            this.$message({
              message: '保存成功',
              type: 'info',
              zIndex: 9999,
            })
            this.close()
            //this.updateing = false;
          })
          .catch((error) => {
            console.log(error)
            this.updateing = false;
          })
      },
      close() {
        this.getFunc({
          target: 'BreakLeave',
          name: 'close'
        })()
      },
    },
  }
</script>
<style scoped>
</style>
